@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/NunitoSans-Bold.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*Setting the color of default value of the <select/>*/
select:required:invalid {
    color: #a7b4bf;
}

option {
    color: #000000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
